@font-face {
font-family: '__drukWide_870bfc';
src: url(/_next/static/media/1aa0add724633261.p.woff2) format('woff2');
font-display: optional;
font-weight: 400;
font-style: normal;
}.__className_870bfc {font-family: '__drukWide_870bfc';font-weight: 400;font-style: normal
}.__variable_870bfc {--font-drukwide: '__drukWide_870bfc'
}

